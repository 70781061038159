import { useState } from "react";
import { Link } from "react-router-dom";

function JoinUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    offers: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with:", formData);
  };

  return (
    <form className="sidebarForm" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">
          First name<span>*</span>
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="lastName">Last name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="email">
          Email<span>*</span>
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="password">
          Password<span>*</span>
        </label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="confirmPassword">
          Confirm password<span>*</span>
        </label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
      </div>

      <div className="checkboxContainer">
        <input
          type="checkbox"
          name="offers"
          checked={formData.offers}
          onChange={handleChange}
        />
        <label className="offers" htmlFor="offers">
          I'd like to receive exclusive offers and news.
        </label>
      </div>
      <button type="submit">Join Now</button>
      <p className="moreLinks">
        By clicking 'Join Now', you agree to KOMHENDO's{" "}
        <Link>Terms & Conditions</Link> and <Link>Privacy Policy.</Link>
      </p>
    </form>
  );
}
export default JoinUs;
