import React, { useEffect, useState } from 'react';
import storyJsonData from '../../JsonFiles/storyData.json';
import { Link } from 'react-router-dom';
import { importImage } from '../../miscellaneousFunc';

export default function BrandStory() {

  const [modifiedContent, setModifiedContent] = useState(null);

  useEffect(()=>{
    let words = storyJsonData.storyHead.split(' ');

    // Construct JSX with <br> after the first three words
    setModifiedContent(
      <>
        {words.slice(0, 3).join(' ')}
        <br />
        {words.slice(3).join(' ')}
      </>
    );
  

  },[])

  return (
    <div className="BrandStoryContainer container">
      <div className="StoryRight">
        {storyJsonData.storyImage.map((image, index) => (
            <div className="storyImageWrapper" key={image.id}>
              {
                <div className="storyDetailsWrapper">
                  {image.imageTag !== "" && (
                    <Link className="storyImgTagLink Jomolhari" to={image.imageTagLink}>{image.imageTag}</Link>
                  )}
                </div>
              }
              {image.imageType === "gradientTypeOne" && (
                <div className={image.imageType}></div>
              )}
              <img src={importImage(image.imageLink)} alt={`Image ${image.id}`} />
            </div>
          ))}
      </div>
    </div>
  )
}
