import { useCartStore } from "../../Store";
import data from "../../JsonFiles/products.json";
import { Link } from "react-router-dom";
import CartProduct from "./CartProduct";
import Mastercard from "../../Images/Mastercard.svg";
import Visa from "../../Images/Visa.svg";
import Paypal from "../../Images/Paypal.svg";

const Cart = () => {
  const cart = useCartStore((state) => state.cart);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const cartItemsPrice = useCartStore((state) => state.cartItemsPrice);
  return (
    <div className="cart">
      {cartItemsNumber === 0 && (
        <>
          <div className="emptyCart Jomolhari">
            <h4>Your cart is Empty!</h4>
            <p>But it doesn't have to be...</p>
          </div>
          <div className="cartCarousel">
            <h4>You May Like</h4>
            <div className="searchResults ">
              {data.map(({ productId, productName, productImages }) => (
                <Link
                  to={`/products?productId=${productId}`}
                  className="dropdownImageElSmall"
                  key={productId}
                >
                  <img src={productImages[0].imageLink} alt={productName} />
                  <p>{productName}</p>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
      {cartItemsNumber > 0 && (
        <>
          <div className="cartProducts">
            {cart.map(({ product, quantity }) => (
              <CartProduct
                key={
                  product.productId +
                  product.productSize.sizeId +
                  product.productColorOption.colorId
                }
                product={product}
                quantity={quantity}
                origin="cart"
              />
            ))}
          </div>
          <div className="cartBottom">
            <div className="subtotalContainer">
              <p>Subtotal:</p>
              <p>${cartItemsPrice}</p>
            </div>
            <Link to="/checkout/cart">
              <button className="goCart">Go To Cart</button>
            </Link>
            <Link to="/">
              <button className="checkout">Proceed To Checkout</button>
            </Link>
            <div className="accepted">
              <p>We accept:</p>
              <img src={Mastercard} alt="Mastercard" />
              <img src={Visa} alt="Visa" />
              <img src={Paypal} alt="Paypal" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Cart;
