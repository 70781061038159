import "../Styles/HeroSection.css";
import "../Styles/BrandStory.css";
import "../Styles/HoverCollection.css";
import "../Styles/DownloadCatalogue.css";
import HeroSection from "../Components/Home/HeroSection";
import AboutUs from "../Components/Home/AboutUs";
import BrandStory from "../Components/Home/BrandStory";
import DownloadCatalogue from "../Components/Home/DownloadCatalogue";
import ProductsCarousel from "../Components/Common/ProductsCarousel";
import { useEffect } from "react";
import { useHomeStore } from "../Store";

const Home = () => {
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  useEffect(() => {
    setSidebar(0);
    setDropdowns(0);
  }, []);

  return (
    <>
      <HeroSection />
      <AboutUs />
      <ProductsCarousel
        sectionTitle={"2024 / Best Sellers"}
        sectionTag={"2024 / Best Sellers"}
      />
      <DownloadCatalogue />
      {/* <HoverCollection /> */}
      <BrandStory />
    </>
  );
};
export default Home;
