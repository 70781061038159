import { useCartStore, useHomeStore } from "../Store";
import { Link } from "react-router-dom";
import Mastercard from "../Images/Mastercard.svg";
import Visa from "../Images/Visa.svg";
import Paypal from "../Images/Paypal.svg";
import PaypalB from "../Images/PaypalB.svg";
import CardKomhendo from "../Icons/CardKomhendo.svg";
import LockKomhendo from "../Icons/LockKomhendo.svg";
import { useEffect } from "react";
import ProductsCarousel from "../Components/Common/ProductsCarousel";
import CartProduct from "../Components/Common/CartProduct";
const CartPage = () => {
  const cart = useCartStore((state) => state.cart);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const cartItemsPrice = useCartStore((state) => state.cartItemsPrice);
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const cartItemsShippingFee = 0;
  useEffect(() => {
    setSidebar(0);
  }, []);
  return (
    <>
      <h2 className="cartPageTitle Jomolhari">
        Shopping Cart <span>( {cartItemsNumber} Item(s))</span>
      </h2>
      {cartItemsNumber === 0 && (
        <div className="cartPageItemsA">
          <p>
            Your cart is empty. Click <Link to="/">here</Link> to continue
            shopping.
          </p>
          <ProductsCarousel
            sectionTitle={"You May Like"}
            sectionTag={"2024 / Best Sellers"}
          />
        </div>
      )}
      {cartItemsNumber > 0 && (
        <div className="cartPageItemsB container">
          <div className="cartPageLeft">
            <div className="cartTableContainer">
              <div className="cartTableHeading">
                <p>Item</p>
                <p style={{ textAlign: "center" }}>Quantity</p>
                <p style={{ textAlign: "right" }}>Subtotal</p>
              </div>
              <div className="cartTableContent">
                {cart.map(({ product, quantity }) => (
                  <CartProduct
                    key={
                      product.productId +
                      product.productSize.sizeId +
                      product.productColorOption.colorId
                    }
                    product={product}
                    quantity={quantity}
                    origin={"cartPage"}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="cartPageRight">
            <h3 className="cartPageFeesTitle">Order Summary</h3>
            <div>
              <p>Subtotal</p>
              <p>${cartItemsPrice}</p>
            </div>
            <div>
              <p>Shipping Fee</p>
              <p>${cartItemsShippingFee}</p>
            </div>
            <div className="grandTotal">
              <p>Grand Total</p>
              <p>${cartItemsPrice + cartItemsShippingFee}</p>
            </div>
            <button>
              <img className="cartPageIcon" src={LockKomhendo} alt="lock" />
              Proceed to Checkout
            </button>
            <button className="paypalButton">
              <img src={PaypalB} alt="paypal" />
            </button>
            <button>
              <img className="cartPageIcon" src={CardKomhendo} alt="card" />
              Debit or Credit Card
            </button>
            <div className="accepted">
              <p>We accept:</p>
              <img src={Mastercard} alt="Mastercard" />
              <img src={Visa} alt="Visa" />
              <img src={Paypal} alt="Paypal" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CartPage;
