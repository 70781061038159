// import React, { useState, useEffect } from 'react';
// import "../Styles/IndividualCollection.css";
// import { useLocation } from 'react-router-dom';
// import products from "../JsonFiles/products.json"; // Importing the products from products.json
// import "../Styles/SearchPage.css";
// import CollectionItem from "../Components/CollectionItem";
// import FilterIcon from "../Icons/FilterKomhendo.svg";

// const SearchResults = () => {
//   const [loadedItems, setLoadedItems] = useState([]);
//   const { searchField } = useLocation().state; // Assuming searchField is passed in state
//   const [displayItems, setDisplayItems] = useState([]);

//   useEffect(() => {
//     // Load more functionality
//     setLoadedItems(products.slice(0, 12)); // Initial load of 12 items
//   }, []);

//   useEffect(() => {
//     if (searchField) {
//       const search = searchField.toLowerCase();
//       const filtered = products.filter(product => {
//         return JSON.stringify(product).toLowerCase().includes(search);
//       });
//       setDisplayItems(filtered);
//     }
//   }, [searchField]);

//   const loadMore = () => {
//     // Increase number of displayed items
//     const numberOfItems = loadedItems.length;
//     const moreItems = products.slice(numberOfItems, numberOfItems + 4);
//     setLoadedItems(loadedItems.concat(moreItems));
//   };

//   return (
//     <div className="searchPageContainer container">
//       <h2 className="title smSearchPageTitle">{`Search Results for "${searchField}"`}</h2>
//       <div className="collectionShowcase">
//       <div className="showcaseTopSearchPage showcase">
//         <h2 className="title lgSearchPageTitle Jomolhari">{`Search Results for "${searchField}"`}</h2>
//         <div className="right rightSearchPage">
//           <p className="itemsNo">{displayItems.length} item(s)</p>
//           <div className="filter">
//             <img src={FilterIcon} alt="filter" />
//             <p>Filters</p>
//           </div>
//         </div>
//       </div>

//         <div className="collectionItems">
//           {displayItems.map((item, index) => (
//             <CollectionItem
//               key={index}
//               id={item.productId}
//               name={item.productName}
//               image={item.productImages[0]} // Display the first image link
//             />
//           ))}
//         </div>
//         {loadedItems.length < products.length && (
//           <button type="button" onClick={loadMore} className="loadButton">
//             Load more
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SearchResults;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import products from "../JsonFiles/products.json";
import CollectionItem from "../Components/CollectionItem";
import FilterIcon from "../Icons/FilterKomhendo.svg";
import Fuse from "fuse.js";

const SearchResults = () => {
  const [loadedItems, setLoadedItems] = useState([]);
  const { searchField } = useLocation().state;
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    setLoadedItems(products.slice(0, 12));
  }, []);

  useEffect(() => {
    if (searchField) {
      const fuseOptions = {
        includeScore: true,
        keys: ["productName", "productDescription", "productCategory"], // Specify the keys to search in
        threshold: 0.4, // Tune this for more or less sensitivity
      };
      const fuse = new Fuse(products, fuseOptions);
      const result = fuse.search(searchField);
      setDisplayItems(result.map((result) => result.item)); // Map over the search results and extract items
    }
  }, [searchField]);

  const loadMore = () => {
    const numberOfItems = loadedItems.length;
    const moreItems = products.slice(numberOfItems, numberOfItems + 4);
    setLoadedItems(loadedItems.concat(moreItems));
  };

  return (
    <div className="searchPageContainer container">
      <div className="collectionShowcase">
        <div className="showcaseTopSearchPage showcase">
          <h2 className="title lgSearchPageTitle">{`Search Results for "${searchField}"`}</h2>
          <div className="right rightSearchPage">
            <p className="itemsNo">{displayItems.length} item(s)</p>
            <div className="filter">
              <img src={FilterIcon} alt="filter" />
              <p>Filters</p>
            </div>
          </div>
        </div>

        <div className="collectionItems">
          {displayItems.map((item, index) => (
            <CollectionItem
              key={index}
              id={item.productId}
              name={item.productName}
              image={item.productImages[0]}
            />
          ))}
        </div>
        {loadedItems.length < products.length && (
          <button type="button" onClick={loadMore} className="loadButton">
            Load more
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
