import React from 'react';
import { importImage } from '../../miscellaneousFunc';
import data from '../../JsonFiles/catalogueSection.json';
import { Link, useNavigate } from 'react-router-dom';

export default function DownloadCatalogue() {
  const navigate = useNavigate();
  const downloadPDF = (e) => {
    e.preventDefault();

    const link = document.createElement('a');
    link.href = data.catalogueCTALink; // URL to the PDF
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="downloadCatalogue container">
      <div className="downloadCatalogueLeft">
        <img
          src={importImage(data.imageLink)}
          alt={data.catalogueTitle}
          className="downloadCatalogueImage"
        />
        <div className="smDownloadCatalogueImageAndGradientContainer">
          {data.smImageType !== '' && (
            <div className={` ${data.smImageType}`}></div> //className = graidentTypeFour
          )}
          <img
            src={importImage(data.smImageLink)}
            alt={data.catalogueTitle}
            className={`smDownloadCatalogueImage`}
          />
        </div>
      </div>
      <div className="downloadCatalogueRight">
        {data.breadCrumb !== '' && (
          <div className="catalogueBreadcrumb Inter">{data.breadCrumb}</div>
        )}
        {data.catalogueTitle !== '' && (
          <div
            className="catalogueBlockTitle Inter-Medium"
            dangerouslySetInnerHTML={{ __html: data.catalogueTitle }}
          />
        )}
        {data.catalogueDescription !== '' && (
          <div
            className="catalogueBlockDescription Inter-Light"
            dangerouslySetInnerHTML={{ __html: data.catalogueDescription }}
          />
        )}
        {data.buttonText !== '' && (
          <div className="buttonsSection">
            <button
              className={`${data.buttonState} Inter-Medium catalogueSectionButton`}
              onClick={() =>
                navigate(
                  `${data.buttonLink}`
                )
              }
            >
              {data.buttonText}
            </button>
            <Link
              className="catalogueCtaLink Inter"
              to="#"
              onClick={downloadPDF}
            >
              {data.catalogueCTA}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
