import React, { useEffect } from "react";
import "../Styles/ProductDisplay.css";
import ProductsCarousel from "../Components/Common/ProductsCarousel";
import ProductDisplay from "../Components/Product/ProductDisplay";

export default function Products() {
  return (
    <>
      <ProductDisplay />
      <ProductsCarousel
        sectionTitle={"You may also like"}
        sectionTag={"2024 / Best Sellers"}
      />
    </>
  );
}
