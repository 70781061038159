import { create } from "zustand";

const isSameProduct = (product1, product2) => {
  return (
    product1.productId === product2.productId &&
    product1.productColorOption.colorId ===
      product2.productColorOption.colorId &&
    product1.productSize.sizeId === product2.productSize.sizeId
  );
};

export const useHomeStore = create((set) => ({
  sidebar: 0,
  setSidebar: (value) => {
    set(() => ({ sidebar: value }));
  },
  dropdowns: 0,
  setDropdowns: (value) => {
    set(() => ({ dropdowns: value }));
  },
}));

export const useCartStore = create((set) => {
  const storedCartDetails = JSON.parse(localStorage.getItem("cartDetails")) || {
    cart: [],
    cartItemsNumber: 0,
    cartItemsPrice: 0,
  };
  return {
    ...storedCartDetails,
    addCartProduct: (newCartProduct) =>
      set((state) => {
        const existingProductIndex = state.cart.findIndex((cartProduct) =>
          isSameProduct(cartProduct.product, newCartProduct)
        );
        if (existingProductIndex === -1) {
          return {
            cart: [...state.cart, { product: newCartProduct, quantity: 1 }],
            cartItemsNumber: state.cartItemsNumber + 1,
            cartItemsPrice: state.cartItemsPrice + newCartProduct.productPrice,
          };
        }
        const updatedCart = [...state.cart];
        updatedCart[existingProductIndex].quantity++;
        return {
          cart: updatedCart,
          cartItemsNumber: state.cartItemsNumber + 1,
          cartItemsPrice: state.cartItemsPrice + newCartProduct.productPrice,
        };
      }),
    removeCartProduct: (oldCartProduct) =>
      set((state) => {
        const existingProductIndex = state.cart.findIndex((cartProduct) =>
          isSameProduct(cartProduct.product, oldCartProduct)
        );
        console.log(existingProductIndex);
        if (existingProductIndex !== -1) {
          const updatedCart = [...state.cart];
          if (updatedCart[existingProductIndex].quantity > 1) {
            updatedCart[existingProductIndex].quantity--;
          } else {
            updatedCart.splice(existingProductIndex, 1);
          }
          return {
            cart: updatedCart,
            cartItemsNumber: state.cartItemsNumber - 1,
            cartItemsPrice: state.cartItemsPrice - oldCartProduct.productPrice,
          };
        }
        return state;
      }),
  };
});
