import { useHomeStore } from "../../Store";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import jsonData from "../../JsonFiles/dropDownImages.json";
import Search from "../Search";

const Dropdown = () => {
  const navigate = useNavigate();
  const dropdowns = useHomeStore((state) => state.dropdowns);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const [isAnimating, setIsAnimating] = useState(false);

  const DropdownItem = ({ data }) => {
    return (
      <div className="dropdownItem">
        <div className="dropdownLeft">
          {data.dropDownSections.map((data,index)=>(
            <div key={data.id} className="section">
            <h2 className="dropdownTitle Jomolhari">{data.dropdownTitle}</h2>
            <hr />
            <div className="dropdownMenu">
              {data.dropDownMenu.map((menuItem, index) => (
                <h6 key={index}>
                  <Link
                    to={`/collection/${menuItem.menuLink}`}
                    onClick={() => setDropdowns(0)}
                  >
                    {menuItem.menuTag}
                  </Link>
                </h6>
              ))}
            </div>
            </div>
          ))}
        </div>
        <div className="dropdownRight">
          {data.dropdownImage.map((image, index) => (
            <div
              className={image.imageType}
              key={index}
              onClick={() => {
                navigate(
                  `/collection/${image.imageRedirectLink}`
                );
                setDropdowns(0);
              }}
            >
              <img className={image.rawImageType} src={image.imageLink} alt={image.imageTag} />
              <p>{image.imageTag}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const dropdownEl = () => {
    switch (dropdowns) {
      case 1:
        return <DropdownItem data={jsonData.Collection} />;
      case 2:
        return <DropdownItem data={jsonData.Pashmina} />;
      case 3:
        return <DropdownItem data={jsonData.Cashmere} />;
      case 4:
        return <Search />;
      default:
        return <></>;
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) setDropdowns(0);
  };

  useEffect(() => {
    if (dropdowns) setIsAnimating(true);
    else {
      const animationTime = setTimeout(() => setIsAnimating(false), 500);
      return () => clearTimeout(animationTime);
    }
  }, [dropdowns]);

  return (
    <div
      className={`dropdownContainer ${
        dropdowns
          ? "dropdownVisible"
          : isAnimating
          ? "dropdownFading"
          : "dropdownHidden"
      }`}
      onClick={handleOutsideClick}
    >
      <div
        className={`dropdown ${dropdowns === 4 ? "searchHeightAdjuster" : ""}`}
      >
        <div className="container">{dropdownEl()}</div>
      </div>
    </div>
  );
};
export default Dropdown;
