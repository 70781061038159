import React from 'react'
import { importImage } from '../../miscellaneousFunc'


export default function KomhendoBanner() {


  return (
    <div className='container adsBannerContainer'>
      <img className='adImage' src={importImage("../Images/komhendoBanner.jpg")} alt="adImage" />
      <img className="adImageSm" src={importImage("../Images/komhendoBanner.jpg")} alt="" />
    </div>
  )
}
