import { useState } from "react";
import SignIn from "./SignIn";
import JoinUs from "./JoinUs";

const Account = () => {
  const [accountTab, setAccountTab] = useState(1);
  const handleTabChange = (value) => {
    if (accountTab === value) return;
    setAccountTab(value);
  };
  return (
    <>
      <div className="accountTabs">
        <span
          className={`${accountTab === 1 ? `active` : ""}`}
          onClick={() => handleTabChange(1)}
        >
          SIGN IN
        </span>
        <span
          className={`${accountTab === 2 ? `active` : ""}`}
          onClick={() => handleTabChange(2)}
        >
          JOIN US
        </span>
      </div>
      {accountTab === 1 && <SignIn />}
      {accountTab === 2 && <JoinUs />}
    </>
  );
};
export default Account;
