import MinusKomhendo from "../../Icons/MinusKomhendo.svg";
import PlusKomhendo from "../../Icons/PlusKomhendo.svg";
import { useCartStore } from "../../Store";

function CartProduct({ product, quantity, origin }) {
  const {
    productId,
    productName,
    productImage,
    productColorOption,
    productPrice,
    productSize,
  } = product;
  const addCartProduct = useCartStore((state) => state.addCartProduct);
  const removeCartProduct = useCartStore((state) => state.removeCartProduct);

  return (
    <>
      {origin === "cart" && (
        <div className="cartProductA">
          <img className="cartProductImage" src={productImage.imageLink} />
          <div className="cartProductDetails">
            <h5 className="cartProductName">{productName}</h5>
            <p className="cartProductInfo">
              {productColorOption.colorName + " / " + productSize.sizeTag}
            </p>
            <div className="cartProductPricing">
              <div className="cartProductQuantity">
                <img
                  src={MinusKomhendo}
                  alt="minus"
                  onClick={() => removeCartProduct(product)}
                />
                {quantity}
                <img
                  src={PlusKomhendo}
                  alt="plus"
                  onClick={() => addCartProduct(product)}
                />
              </div>
              <p className="cartProductPrice">${productPrice * quantity}</p>
            </div>
          </div>
        </div>
      )}
      {origin === "cartPage" && (
        <>
          <div className="cartProductB">
            <img className="cartProductImage" src={productImage.imageLink} />
            <div className="cartProductDetails">
              <h5 className="cartProductName">{productName}</h5>
              <p className="cartProductInfo">
                {productColorOption.colorName + " / " + productSize.sizeTag}
              </p>
            </div>
          </div>
          <div className="cartProductQuantity cartProductQuantityB">
            <img
              src={MinusKomhendo}
              alt="minus"
              onClick={() => removeCartProduct(product)}
            />
            {quantity}
            <img
              src={PlusKomhendo}
              alt="plus"
              onClick={() => addCartProduct(product)}
            />
          </div>
          <p className="cartProductPrice cartProductPriceB">
            ${productPrice * quantity}
          </p>
        </>
      )}
    </>
  );
}
export default CartProduct;
