import React from "react";
import MenuHamburgerIcon from "../../Icons/MenuHamburgerKomhendo.svg";
import SearchIcon from "../../Icons/SearchKomhendo.svg";
import { useHomeStore } from "../../Store";
import KomhendoLogo from "../../Icons/Ko.MhendoLogo.svg";
import { Link } from "react-router-dom";

export default function SmNavBar() {
  const dropdowns = useHomeStore((state) => state.dropdowns);
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const handleDropdownClick = (value) => {
    if (dropdowns === value) setDropdowns(0);
    else setDropdowns(value);
  };
  return (
    <div className="smNavBarContainer smContainer">
      <Link className="smNavBarLogoLeft Jomolhari">
        <img src={KomhendoLogo} className="smKomhendoLogoImage" alt="" />
      </Link>
      <div className="smNavBarItemsRight">
        <img src={SearchIcon} alt="search" onClick={() => setDropdowns(4)} />
        <img src={MenuHamburgerIcon} alt="menu" onClick={() => setSidebar(1)} />
      </div>
    </div>
  );
}
