import LocomotiveScroll from "locomotive-scroll";
import { useEffect } from "react";
import "./Styles/locomotive-scroll.css"

export const importImage = (path) => {
  return `${process.env.PUBLIC_URL}${path}`;
};

export const scrollToTop= ()=> {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

export const useLocoScroll = (start)=>{
  useEffect(()=>{
    if(!start) return;
    console.log("called");
    const scrollEl = document.querySelector('#productDisplayContainer')
    const locoScroll = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      multiplier: 1,
      class: 'is-reveal'
    })
  },[start])
}

export const capitalizeFirstLetter = (string) => {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};